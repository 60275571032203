<template>
  <div>
    <modal-carousel
      ref="modalCarousel"
      v-if="modal.exibir"
      :exibir.sync="modal.exibir"
      :images="imagensCarousel"
      :imageClick="imageClick"
    ></modal-carousel>
    <b-card no-body class="mb-0">
      <titulo-card-com-voltar
        :voltar="registroAnterior"
        :titulo="tituloCard()"
      />

      <div class="m-2 px-2">
        <b-row>
          <b-col cols="9" class="pb-2">
            <div class="font-weight-bold">Especificação:</div>
            <div>{{ (itemData && itemData.sub_tipo.nome) || '-' }}</div>
          </b-col>
          <b-col cols="4" class="pb-2">
            <div class="font-weight-bold">Localização:</div>
            <div>
              {{
                (itemData &&
                  itemData.localizacao &&
                  itemData.localizacao.nome) ||
                '-'
              }}
            </div>
          </b-col>
          <b-col cols="3" class="pb-2">
            <div class="font-weight-bold">Tipo:</div>
            <div>
              {{
                (itemData &&
                  itemData.sub_tipo &&
                  itemData.sub_tipo.tipo.nome) ||
                '-'
              }}
            </div>
          </b-col>
          <b-col cols="2">
            <div class="font-weight-bold">Status:</div>
            <div>
              {{ (itemData && itemData.status && itemData.status.nome) || '-' }}
            </div>
          </b-col>
          <b-col cols="4">
            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="RegistradoPor"
                plain
              >
                Registrado por:
              </b-form-checkbox>
            </div>
            <div>{{ (itemData && itemData.registrado_por.name) || '-' }}</div>
          </b-col>
          <b-col cols="8">
            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="RegistradoEm"
                plain
              >
                Registro em:
              </b-form-checkbox>
            </div>
            <div>
              {{ (itemData && mascaraDataBr(itemData.created_at)) || '-' }}
            </div>
          </b-col>
          <b-col cols="4" class="mt-2">
            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="AprovadoPor"
                plain
              >
                Aprovador Por:
              </b-form-checkbox>
            </div>
            <div>
              {{
                (itemData &&
                  itemData.aprovado_por &&
                  itemData.aprovado_por.name) ||
                '-'
              }}
            </div>
          </b-col>
          <b-col cols="8" class="mt-2">
            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="AprovadoEm"
                plain
              >
                Aprovador em:
              </b-form-checkbox>
            </div>
            <div>{{ itemData && mascaraDataBr(itemData.aprovado_em) }}</div>
          </b-col>
          <b-col cols="9" class="mt-2">
            <div class="font-weight-bold">Observação do registro:</div>
            <div>{{ (itemData && itemData.descricao) || '-' }}</div>
          </b-col>
          <b-col cols="6" class="mt-2">
            <div class="font-weight-bold">Título Abreviado (p/ relatório):</div>
            <div>
              {{ (itemData && itemData.titulo_abreviado) || 'Não informado' }}
            </div>
          </b-col>
          <b-col cols="12" class="mt-2">
            <div class="font-weight-bold">Apontamento:</div>
            <div>{{ itemData && itemData.observacoes }}</div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col cols="12">
          <b-card v-if="itemData && itemData.imagens" class="px-2">
            <div
              class="font-weight-bold font-medium-2 mb-2 pb-1 border-bottom d-flex justify-content-between"
            >
              <div>Imagens Registradas ({{ itemData.imagens.length }})</div>
              <b-button
                variant="outline-primary"
                @click="toggleSelectAllItems(itemData)"
              >
                {{ toggleSelectAllLabel }}
              </b-button>
            </div>
            <b-card-text>
              <h6 class="d-block pb-2">
                Selecione as fotos para aparecem no detalhamento
              </h6>
              <b-row>
                <b-form-group class="w-100">
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="imagensSelecionadas"
                    name="flavour-2"
                    class="wrapper-images"
                  >
                    <div
                      class="d-flex"
                      v-for="img in itemData.imagens"
                      :key="img.id"
                    >
                      <b-form-checkbox :value="img.id"> </b-form-checkbox>
                      <b-card
                        no-body
                        class="mb-0"
                        @click.stop="tratarEventoModalImages(img)"
                      >
                        <image-overlay :url="img.url" thumbnail />
                      </b-card>
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <div
        class="d-flex flex-column align-items-center justify-content-center pb-3"
      >
        <small class="mb-2"
          >Total de fotos selecionadas: :{{ imagensSelecionadas.length }}</small
        >
        <b-button
          variant="primary"
          size="md"
          @click="gerarDetalhamentoBtn(itemData)"
        >
          Gerar Detalhamento
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BImg,
  BRow,
} from 'bootstrap-vue'
import { mascaraDataBr } from '@/@core/utils/filter'
import { onUnmounted, ref, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Swal from 'sweetalert2'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'
import ImageOverlay from '@/views/components/componentesPensou/ImageOverlay.vue'
import ModalCarousel from '@/views/components/componentesPensou/ModalCarousel.vue'
import useItemList from '@/views/apps/registros/list/useItemList'
import userStoreModule from '../registroStoreModule'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BButton,
    TituloCardComVoltar,
    BCard,
    BRow,
    BCol,
    BImg,
    BCardText,
    ImageOverlay,
    ModalCarousel,
  },
  setup() {
    const itemData = ref(null)
    const camposParaExibir = ref([
      'RegistradoEm',
      'AprovadoEm',
      'RegistradoPor',
      'AprovadoPor',
    ])
    const registroAnterior = ref(null)
    const imagensSelecionadas = ref([])
    const modal = ref({ exibir: false })
    const imagensCarousel = ref([])
    const imageClick = ref({})

    const toggleSelectAllLabel = computed(() => {
      return imagensSelecionadas.value.length === itemData.value.imagens.length
        ? 'Desmarcar todos'
        : 'Selecionar todos'
    })

    const REGISTRO_APP_STORE_MODULE_NAME = 'app-registros'

    // Register module
    if (!store.hasModule(REGISTRO_APP_STORE_MODULE_NAME))
      store.registerModule(REGISTRO_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REGISTRO_APP_STORE_MODULE_NAME))
        store.unregisterModule(REGISTRO_APP_STORE_MODULE_NAME)
    })

    const { gerarDetalhamento } = useItemList()

    registroAnterior.value = `obras/registros/${router.currentRoute.params.idObra}`

    const gerarDetalhamentoBtn = (itens) => {
      gerarDetalhamento({
        idObra: router.currentRoute.params.idObra,
        idRegistro: router.currentRoute.params.idRegistro,
        imagens: imagensSelecionadas.value,
        camposParaExibir: camposParaExibir.value,
      })

      Swal.fire({
        title: 'Gerando detalhamento...',
        html: 'Seu detalhamento está sendo gerado, aguarde...',

        icon: 'info',
        didOpen: () => {
          Swal.showLoading()
        },
        backdrop: false,
      })
    }

    store
      .dispatch('app-registros/fetchRegistro', {
        idObra: router.currentRoute.params.idObra,
        idRegistro: router.currentRoute.params.idRegistro,
      })
      .then((response) => {
        itemData.value = response.data
      })
      .catch((error) => {
        if (error.response.status === 404) {
          itemData.value = undefined
        }
      })
    const tituloCard = () => {
      if (itemData.value) {
        return `Gerar Detalhamento de: ${itemData.value.sub_tipo.nome} - ${itemData.value.localizacao.nome}`
      }
      return 'Descrição não informada'
    }
    const toggleSelectAllItems = (item) => {
      if (imagensSelecionadas.value.length === item.imagens.length) {
        imagensSelecionadas.value = []
      } else {
        imagensSelecionadas.value = item.imagens.map((img) => img.id)
      }
    }

    const tratarEventoModalImages = (imagem) => {
      imagensCarousel.value = itemData.value.imagens
      imageClick.value = imagem
      modal.value.exibir = true
    }

    return {
      modal,
      itemData,
      tituloCard,
      registroAnterior,
      mascaraDataBr,
      imagensSelecionadas,
      gerarDetalhamentoBtn,
      camposParaExibir,
      toggleSelectAllItems,
      tratarEventoModalImages,
      imagensCarousel,
      toggleSelectAllLabel,
      imageClick,
    }
  },
}
</script>
<style lang="scss" scoped>
.wrapper-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
}
</style>
